

.form-control:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
} 


.tradeBalance:hover {
	text-decoration: underline;
}
.textBalance {
    font-size: 85%;
    color:  #ffffff;
    text-align: right;
  
  }

.inputCustom{
    background-color: #0b0b0b !important;
    color: #ffffff !important;
    width: 100% !important;
    border: 0px !important;
    border-style: solid !important;
    padding: 1vw;
    margin-top: 0.7vw;
    font-size: 2vw;
    
}
.inputCustom:focus {
    outline:none !important;
}