.testCase{
    border: 0px !important;
    border-style: solid !important;
    border-color: blue !important;
}




  .ps-menuitem-root.css-1tqrhto {
    background-color: #424242 !important;
    color: #ffffff !important;
  }
  .ps-menuitem-root.css-1tqrhto:hover {
    background-color: black !important;
    color: black !important;
    border-radius: 8px !important;
    text-decoration: none !important;
  }

  