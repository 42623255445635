.mainpageContent{
    font-size: 1.2vw !important;
}

.infoRow{
    min-height: 120px;
    padding: 2vw;
  }

.infoBox{
    border: 3px  !important;
    border-style: solid !important;
    border-radius:  12px !important;
    border-color: #fa2aa0 !important;
    color: #ffffff !important;
    text-align: center;
    min-height: 150px !important;
    z-index: 2 !important;
    padding: 15px !important;
  }

.infoBoxItem{
    font-size: 2.3vw !important;
    margin-top: 10px;
}

.infoBoxItem2{
    font-size: 1.8vw !important;
    text-transform: uppercase;
}

.mainTable{
    background-color: transparent !important;
}

.firstRow {
    border-right: 1px;
    border-left: 0px;
    border-top: 0px;
    border-bottom: 0px;
    border-style: solid;
    border-color: white;
    padding-right: 10px;
}