
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200&family=Montserrat:wght@400;700&family=Roboto:wght@300;700&display=swap');

@font-face {
  font-family: "PosteramaRegular";   /*Can be any text*/
  src: local("PosteramaRegular"),
    url("./fonts/PosteramaRegular.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSansLight";   /*Can be any text*/
  src: local("OpenSansLight"),
    url("./fonts/OpenSansLight.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSansBold";   /*Can be any text*/
  src: local("OpenSansBold"),
    url("./fonts/OpenSansBold.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSansRegular";   /*Can be any text*/
  src: local("OpenSansRegular"),
    url("./fonts/OpenSansRegular.ttf") format("truetype");
}

.modal-content{
  border: 1px !important;
  
  border-style: solid !important;
  border-color: #fa2aa0 !important;
}

.modal-header{
  background-color: black;
  color: white;
  border-bottom: 1px solid #fa2aa0 !important;
}

.modal-body{
  background-color: black !important;
  color: white;
}
.modal-footer{
  background-color: black !important;
  color: white;
  border-top: 0px solid #fa2aa0 !important;
}

.close{
  color: white !important;
}
.ps-menuitem-root.css-1t8x7v1:hover {
  background-color: #000000 !important;
  background-color: #424242;
  color: #000000;
}

.assetSelectList{
  background-color: black;
  font-size: 0.9vw !important;
  padding: 0.6vw;
  border: 1px solid #fa2aa0 !important;
  
}
.assetSelectList:last{
  border-bottom: 0px;
  
}
.assetSelectList:hover{
  background-color: #1afba9;
  color: black;
}
.searchField{
  background-color: #1afba9 !important;
  color: black
}

.socials:hover {
  filter: invert(11%) sepia(70%) saturate(662%) hue-rotate(77deg)
    brightness(98%) contrast(100%);
  text-decoration: none !important;
}

.mainArea{
  width: 100% !important;
  margin: 0 !important;
  margin: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  border-style: solid;
  border-width: 0px;
  border-color: blue !important;
  width: 100% !important;
  overflow-y: scroll; /* Show vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.mainTable {
  font-size: 0.9vw;
}

.buttonText {
  font-size: 0.9vw !important;
}

.backgroundImage {
  position: relative;
  width: 100%;
  background-image: url(./img/Poly_BG.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.layer {
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.transparent{
  background-color: rgba(0, 0, 0, 0.4) !important;
}


.web3modal-modal-card{
  width: 400px!important;
  min-width: unset!important;
  max-width: unset!important;
}

.web3modal-provider-container{
  flex-direction: row!important;
}

.web3modal-provider-description{
  display:none;
}

.menuButton{
  height: 42px !important;
  margin: auto !important;
  border: 1px solid !important;
  border-radius:  10px !important;
  font-size: 0.9vw !important;
}

.show>.dropdown-menu{
  display: block;
  position:absolute;
}

.bold{
  font-weight: bold;
}


body { 
	font-family: 'OpenSansRegular', sans-serif;
  overflow-y: scroll; /* Show vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}


.navbar{
  text-transform: uppercase;
  font-size: 14px !important;
  letter-spacing: .9px !important;
  font-weight: 300 !important;
  line-height: 30px !important;
  font-style: normal !important;
  padding-left: 2vw !important;
  padding-right: 2vw !important;
  width: 100% !important;
}

.nav-link:hover{
  color:#1afba9 !important;
}

.nav-link-active{
  color: #1afba9 !important;
}

.link {
  color: #1afba9;
  text-decoration: none;
  background-color: transparent;
}
.link:hover {
  color: #1afba9;
  text-decoration: underline;
}


.border-3 {
    border-width:3px !important;
}

.mainModal { 
	background: #171A21 !important; 
	width: 90%;
}

.mainContent {
  position:absolute;
  top:0%;
  left:0%;
  right:0%;
}

.underlined-fuchsia {
  border-bottom-style: solid;
  border-color: #FF00FF;
}

.tooltip-inner {
  background-color: #666666 !important;
  color: #666666 ;
}

.vertically-centered {
  
}



.bottom-right {
  position: absolute;
  right: 3%;
  bottom:3%;
  
}

.copyright {
  font-size: 0.75vw;
  color: #E4E4E4;
}



.selectAssetItem:hover {
	background-color: #1afba9;
}
.selectAssetItem {
  background-color: black;
	outline: 1px solid #666666;
}

.addressGovernance{
	font-size: 85%;
}

.smaller{
	font-size: 85%;
}
.vertical_center {
    display: flex;
    align-items: center;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

#selectMain {
  border-style: solid;
  border-color: #ffffff;  
}

#selectMain:hover {
  border-style: solid;
  border-color: #1afba9;

}

#greyModal {
  background-color: #939393 !important;  
}




input {
  float: right !important;
}

input[type=text] {
  border: 0px;
  border-radius: 4px;
  background-color: #f8f9fa;
  float: right !important;
}

input[type=number] {
  border: 0px;
  border-radius: 4px;
  background-color: #f8f9fa;
  float: right !important;
}

input[type=decimals] {
  border: 0px !important;
  border-radius: 4px;
  background-color: #f8f9fa;
  float: right !important;
}

#mainBox{
	border: 0px solid !important;
	border-color: #ffffff  !important;
	border-radius:  20px !important;
  box-shadow: 0px 0px 20px #939393;
  background: rgba(0, 0, 0, 0.6) !important;
  
}
#mainBoxInner{
  border: 0px solid !important;
  border-color: #1afba9  !important;
  border-radius:  20px !important;
}

#mainBoxClicked{
  border: 2px solid !important;
  border-color: #1afba9  !important;
  border-radius:  20px !important;
}

#mainBoxUpLeft{
  border: 0px solid !important;
  border-color: #1afba9  !important;
  border-top-left-radius: 20px !important;
}
#mainBoxUpRight{
  border: 0px  !important;
  border-color: #1afba9  !important;
  border-top-right-radius: 20px !important;
}
#mainBoxDownLeft{
  border: 0px  !important;
  border-color: #1afba9  !important;
  border-bottom-left-radius: 20px !important;
}
#mainBoxDownRight{
  border: 0px  !important;
  border-color: #1afba9  !important;
  border-bottom-right-radius: 20px !important;
}
#mainBoxUp{
  border: 0px  !important;
  border-color: #1afba9  !important;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
}
#mainBoxDown{
  border: 0px  !important;
  border-color: #1afba9  !important;
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

#innerBox{
  border: 0px  !important;
  border-style: solid !important;
  border-color: #1afba9  !important;
  border-radius:  16px !important;
}

#innerBoxUpLeft{
  border: 0px  !important;
  border-color: #1afba9  !important;
  border-top-left-radius: 16px !important;
}
#innnerBoxUpRight{
  border: 0px  !important;
  border-color: #1afba9  !important;
  border-top-right-radius: 16px !important;
}
#innerBoxDownLeft{
  border: 0px  !important;
  border-color: #1afba9  !important;
  border-bottom-left-radius: 16px !important;
}
#innerBoxDownRight{
  border: 0px  !important;
  border-color: #1afba9  !important;
  border-bottom-right-radius: 16px !important;
}
#innerBoxUp{
  border: 0px  !important;
  border-color: #1afba9  !important;
  border-top-right-radius: 16px !important;
  border-top-left-radius: 16px !important;
}
#innerBoxDown{
  border: 0px  !important;
  border-color: #1afba9  !important;
  border-bottom-right-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
}

#governanceBox{
  border: 0px solid !important;
  border-radius:  20px !important;
  background-color: #2d2d2d !important;
  color:  white !important;
}

#governanceBoxClicked{
  border: 0px solid !important;
  border-radius:  20px !important;
  background-color: #2d2d2d  !important;
  color:  #1afba9 !important;
}

#tradeBoxText{
  font-size: 120%;
  font-weight: bold;
}

#subBox{
  border: 2px  !important;
  border-color: #fa2aa0  !important;
  border-radius:  20px !important;
}

.subBox{
  border: 2px  !important;
  border-style: solid !important;
  border-color: #fa2aa0  !important;
  border-radius:  20px !important;
  background-color: #0b0b0b !important;
  text: white;
}
#buttonRounded{
  border: 1px  !important;
  border-color: #1afba9  !important;
  border-radius:  15px !important;;
}

#submit{
  border: 1px  !important;
  border-color: #1afba9  !important;
  border-radius:  20px !important;;
}

.issuaaButton{
  border: 2px  !important;
  padding: 10px !important;
  border-style: solid !important;
  border-color: #1afba9  !important;
  border-radius:  15px !important;
  color: white !important;
  background-color: transparent !important;
  margin-top: 10px;
  font-size: 1vw !important;
}

.issuaaButton:hover{
  border: 3px  !important;
  padding: 9px !important;
  border-style: solid !important;
  border-color: #1afba9  !important;
  border-radius:  15px !important;
  color: white !important;
  margin-top: 10px;
}
.issuaaButtonPink{
  border: 2px  !important;
  padding: 10px !important;
  border-style: solid !important;
  border-color: #fa2aa0  !important;
  border-radius:  15px !important;
  color: white !important;
  background-color: transparent !important;
  margin-top: 10px;
  margin-top: 10px;
  font-size: 1vw !important;
}

.issuaaButtonPink:hover{
  border: 3px  !important;
  padding: 9px !important;
  border-style: solid !important;
  border-color: #fa2aa0  !important;
  border-radius:  15px !important;
  color: white !important;
  margin-top: 10px;
}

.issuaaButtonDeactivated{
  border: 2px  !important;
  padding: 10px !important;
  border-style: solid !important;
  border-color: #424242  !important;
  border-radius:  15px !important;
  color: white !important;
  background-color: transparent !important;
  margin-top: 10px;
  font-size: 1vw !important;
}

.roundedCorners  {
  border-radius: 20px;

}

table  {
  border-collapse: collapse;
  overflow: hidden;
  font-size: 0.9em;

}

td {
  padding: 1em;
  border-bottom: 1px solid #fa2aa0 !important;
  font-weight: lighter !important;
}



th {
  padding: 1em;
  border-bottom: 1px solid #fa2aa0 !important; 
  border-top: 0px solid #430f4d !important;
  font-weight: bold !important;
}

tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

tr:last-child {
  padding: 1em;
  border-bottom: 0px solid #fa2aa0 !important; 
}


#largeIPT{
  text-shadow: 0 0 3px black;
}

.loadingBlockchainModal {
  border-radius:  0px !important;
  border-color: #fa2aa0 !important;
  border-style: solid !important;
  background-color: #0c0c0c !important;
  border: 2px !important;
  color: white;

}

.loadingBlockchainModalOuter{
  background-color: transparent !important;
}
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
    height: 100%;
    font-family: 'OpenSansRegular', sans-serif !important;
}
