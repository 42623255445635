.farmContent:first-child{
    border:#000000 solid !important
  }
  .farmRowMain{
    background-color: rgba(255, 255, 255, 0.2) !important;
    border: 0px solid!important;
    border-bottom: 1px solid !important;
    border-color: rgba(255, 255, 255, 0.5) !important;
    padding: 0.7vw !important;
    font-size: 0.8vw;
  }
  .farmRowMain:first-child{
    border-radius: 10px 10px 0px 0px !important;
  }
  .farmRowMain:last-child{
    border-radius: 0px 0px 10px 10px !important;
  }
  .farmRow{
    background-color: rgba(255, 255, 255, 0.5) !important;
    display:flex;
    justify-content:space-between;
  }
  
  .farmBox {
    border: 1px solid !important;
    border-radius: 10px !important;
    border-color: black !important;
    color: black !important;
    background-color: rgba(255, 255, 255, 0.7) !important;
    display:flex; 
    padding: 1vw !important;
    margin: 1vw !important;
  }
  
  .farmToken {
    font-size: 1.0vw;
    padding: 0.7vw !important;
    padding-left: 1.5vw !important;
  }
  
  .farmButton {
    font-size: 0.8vw !important;
  }
  
  #farms-table {
    background-color: #430f4d;
    font-size: 0.8em;
    padding: 40px;
    width: 100%;
  }
  
  #farms-table tr {
    width: 100% !important;
  }
  
  #farms-table td {
    background-color: #f8f9fa;
  }